<script lang="ts">
import { calc } from './stores/gameBrain';
import { currentHP, currentTempHP } from './stores/stores';
import Value from './Value.svelte';
import Card from './Card.svelte';
</script>

<Card>
  <h3>Hit points</h3>
  <div class="hp">
    <div>
      <div>Max <Value prop="hit_points"/></div>
      <div><input type="number" min="0" bind:value={$currentHP} /></div>
      <div><button onclick={() => $currentHP = $calc('hit_points')}>Reset</button></div>
    </div>
    <div>
      <div>Temp HP</div>
      <input type="number" min="0" bind:value={$currentTempHP} />
    </div>
  </div>
</Card>

<style>
.hp {
  display: flex;
  gap: 1em;
}

button {
  width: 100%;
  height: 2.5em;
}
</style>
