<script lang="ts">
import { armor, selectedArmorName } from './stores/items';
import { acBonuses } from './stores/stores';
import Toggle from './Toggle.svelte';
import Card from './Card.svelte';
let armorNames = $armor?.map((a) => a.name);

</script>

<Card>
  <h3>Current armor</h3>
  {#if armorNames}
  <select class="selector" onchange={(e) => $selectedArmorName = e.currentTarget.value} value={$selectedArmorName}>
    {#each armorNames as armor}
      <option value={armor}>{armor}</option>
    {/each}
  </select>
  {/if}
  <div>
    <Toggle
      label="Shield"
      checked={!!$acBonuses['shield']}
      toggle={() => acBonuses.toggle('shield', 2)}
      />
  </div>
</Card>

<style>
.selector {
  margin: 1em 0;
}
</style>
