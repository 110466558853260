<script lang="ts">
import { API_URL } from "../core/api";
import Card from "../Card.svelte";

let userId: string | undefined = $state();
let password: string | undefined = $state();
let error: string | undefined = $state();

function submitHandler() {
  if(userId && password) {
    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        password
      }),
    }).then((res) => {
      if (res.status === 200) {
        window.location.assign("/");
      } else {
        error = "Error logging in";
      }
    });
  }
}

</script>
<Card>
  <select name="user_id" bind:value={userId}>
    <option value="62946435-5f60-428d-b5bf-d37a9c35a909">Marlon</option>
    <option value="6982d632-a5f3-4dae-8397-864f0223befc">Bas</option>
    <option value="e533ec81-96b0-48c4-86f2-8539288a3377">Jasper</option>
    <option value="4346cf3e-6299-42bf-965e-392f7ad4200a">Marco</option>
    <option value="4e61481f-7c01-4d39-b10a-0f765bdb1454">Mark</option>
    <option value="c9b633c9-faf9-4524-99d7-6bd53ded3e39">Steve</option>
  </select>
  <br />
  <label for="password">Password</label><br />
  <input name="password" type="password" bind:value={password} /><br />
  <button onclick={submitHandler}>Login</button>
  {#if error}<p>{error}</p>{/if}
</Card>
