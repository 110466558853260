import { writable, derived, type Readable } from "svelte/store";
import  type { Class, Spell, Subclass } from "@character-sheet/types";
import asyncDerived from "./asyncDerived";
import { calc } from "./gameBrain";
import { loadSpells } from "../core/api";
import { Stash } from "../core/stash";
import { classData, subclass } from "./classes";
import { preparedSpells } from "./stores";

export enum FilterState {
  Off = "off",
  Exclude = "exclude",
  Include = "include",
}

export function cycle_filter(input: FilterState): FilterState {
  switch (input) {
    case FilterState.Off:
      return FilterState.Exclude;
    case FilterState.Exclude:
      return FilterState.Include;
    case FilterState.Include:
      return FilterState.Off;
    default:
      return FilterState.Off;
  }
}

function filterStore(name: string) {
  const initialFilter = localStorage.getItem(name) as FilterState;
  const filter = writable<FilterState>(initialFilter);
  filter.subscribe((newValue) => localStorage.setItem(name, newValue));
  return filter;
}

export const ritualFilter = filterStore("ritualFilter");
export const concentrationFilter = filterStore("concentrationFilter");
export const higherLevelFilter = filterStore("higherLevelFilter");
export const domainSpellFilter = filterStore("domainSpellFilter");
export const preparedFilter = filterStore("preparedFilter");
export const rangedFilter = filterStore("rangedFilter");
export const actionFilter = filterStore("actionFilter");
export const bonusActionFilter = filterStore("bonusActionFilter");
export const vComponentFilter = filterStore("vComponentFilter");
export const sComponentFilter = filterStore("sComponentFilter");
export const mComponentFilter = filterStore("mComponentFilter");
export const damageFilter = filterStore("damageFilter");
export const conditionFilter = filterStore("conditionFilter");

export const spells: Readable<Spell[]> = asyncDerived<Spell[]>(
  [classData, subclass, calc],
  async ([$classData, $subclass, $calc]: [Class, Subclass, Function]): Promise<Spell[]> => {
    let spells: Spell[] | null = Stash.getItem('spells');

    if (spells !== null) {
      return spells;
    }
    
    if ($classData) {
      spells = await loadSpells($classData.name ?? null, $subclass?.shortName, $calc("max_spell_level"));
      Stash.setItem('spells', spells);
      return spells;
    }

    // No cached spells and no classData yet, so just return an empty list
    return [];
  },
  [],
);

type SpellsByLevel = { [level: number]: [Spell] };

const filteredSpells = derived(
  [
    spells,
    concentrationFilter,
    ritualFilter,
    higherLevelFilter,
    rangedFilter,
    domainSpellFilter,
    actionFilter,
    bonusActionFilter,
    vComponentFilter,
    sComponentFilter,
    mComponentFilter,
    damageFilter,
    conditionFilter,
  ],
  ([
    $spells,
    $concentrationFilter,
    $ritualFilter,
    $higherLevelFilter,
    $rangedFilter,
    $domainSpellFilter,
    $actionFilter,
    $bonusActionFilter,
    $vComponentFilter,
    $sComponentFilter,
    $mComponentFilter,
    $damageFilter,
    $conditionFilter,
  ]): Spell[] => {
    // TODO: Do we need support for multiple time/durations?
    return $spells.filter((spell: Spell) => {
      if ($actionFilter === FilterState.Include)
        return spell.time[0]?.unit === "action";
      if ($actionFilter === FilterState.Exclude)
        return spell.time[0]?.unit !== "action";
      if ($bonusActionFilter === FilterState.Include)
        return spell.time[0]?.unit === "bonus";
      if ($bonusActionFilter === FilterState.Exclude)
        return spell.time[0]?.unit !== "bonus";
      if ($ritualFilter === FilterState.Include) return spell.meta?.ritual;
      if ($ritualFilter === FilterState.Exclude) return !spell.meta?.ritual;
      if ($concentrationFilter === FilterState.Include) {
        const t = spell.duration[0]?.type;
        return (t === "timed" || t === "instant" ) && spell.duration[0]?.concentration;
      }
      if ($concentrationFilter === FilterState.Exclude) {
        const t = spell.duration[0]?.type;
        return (t === "timed" || t === "instant" ) && !spell.duration[0]?.concentration;
      }
      if ($higherLevelFilter === FilterState.Include)
        return spell.entriesHigherLevel;
      if ($higherLevelFilter === FilterState.Exclude)
        return !spell.entriesHigherLevel;
      if ($rangedFilter === FilterState.Include)
        return spell.range?.distance?.type !== "touch";
      if ($rangedFilter === FilterState.Exclude)
        return spell.range.distance?.type === "touch";

      if ($domainSpellFilter === FilterState.Include) return spell.additionalSpell;
      if ($domainSpellFilter === FilterState.Exclude) return !spell.additionalSpell;
      if ($vComponentFilter === FilterState.Include) return spell.components.v;
      if ($vComponentFilter === FilterState.Exclude) return !spell.components.v;
      if ($sComponentFilter === FilterState.Include) return spell.components.s;
      if ($sComponentFilter === FilterState.Exclude) return !spell.components.s;
      if ($mComponentFilter === FilterState.Include) return spell.components.m;
      if ($mComponentFilter === FilterState.Exclude) return !spell.components.m;

      if ($damageFilter === FilterState.Include) return spell.damageInflict;
      if ($damageFilter === FilterState.Exclude) return !spell.damageInflict;

      if ($conditionFilter === FilterState.Include) return spell.conditionInflict;
      if ($conditionFilter === FilterState.Exclude) return !spell.conditionInflict;

      return true;
    });
  },
);

export const filteredSpellsByLevel = derived(
  filteredSpells,
  ($filteredSpells): SpellsByLevel => spellsByLevel($filteredSpells),
);


export const preparedSpellsByLevel = derived([
  filteredSpells,
  preparedSpells
], ([$filteredSpells, $preparedSpells]): SpellsByLevel => {
  return spellsByLevel($filteredSpells.filter((spell: Spell) => {
    const prepared = Object.entries($preparedSpells).some(([name, p]) => spell.name === name && p);
    return spell.additionalSpell || prepared
  }));
},
);


const spellsByLevel = (spells: Spell[]): SpellsByLevel => {
  return spells.reduce(
    (acc: SpellsByLevel, curr: Spell) => (
      acc[curr.level] ? acc[curr.level]?.push(curr) : (acc[curr.level] = [curr]),
      acc
    ),
    {},
  )};


//export const preparedSpellsCount = writable({ total: -1, levels: {} });

export const preparedSpellsCount = derived(
  preparedSpells,
  ($preparedSpells) => {

    const levels: { [level: number]: number } = {};
    
    for (let level: number = 1; level <= 9; level++) {
      levels[level] = Object.values($preparedSpells).filter((s: Spell | null) => {
        // Don't count Cantrips and additional spells
        return s && s.level === level && !s.additionalSpell;
      }).length;
    }
    
    return {
      total: Object.values($preparedSpells).filter((s: Spell | null) => {
        // Don't count Cantrips and additional spells
        return s && s.level > 0 && !s.additionalSpell;
      }).length,
      levels,
    };
  },
);
