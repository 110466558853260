<script lang="ts">
  import Card from "../Card.svelte";
  import Entries from "../components/Entries.svelte";
  import { gameBrainErrors } from "../stores/gameBrain";
  import { state, rules } from '../stores/stores';
  import { races, race, characterRace } from '../stores/races';
  import { characterClass, classNames, characterSubclass, classes } from '../stores/classes';
  import { saveState } from "../core/api";
  import { level } from '../stores/stores';
</script>

<section class="columns">
  <div>
    <h2>Character</h2>
    <h3>Race</h3>
    {#if $races}
      <select
        name="race"
        value={$characterRace}
        onchange={(e) => {$characterRace = e.currentTarget.value}}
        >
        {#each $races as r}
          <option value={r.name}>{r.name}</option>
        {/each}
      </select>
    {/if}
    <h3>Class</h3>
    {#if $classNames}
      <select
        name="class"
        value={$characterClass}
        onchange={(e) => $characterClass = e.currentTarget.value}
        >
        {#each Object.entries($classNames) as [value, label]}
          <option value={value}>{label}</option>
        {/each}
      </select>
    {/if}
    {#if $classes && $classes.subclass}
      <h3>Subclass</h3>
      <select
        name="subclass"
        value={$characterSubclass}
        onchange={(e) => $characterSubclass = e.currentTarget.value}
        >
        {#each $classes.subclass as subclass}
          <option value={subclass.shortName}>{subclass.name}</option>
        {/each}
      </select>
    {/if}
    <h3>Level</h3>
    <input
      type="text"
      bind:value={$level}
      min={1}
      max={20}
      />  
  </div>
  <div>
    {#if $race}
      <Entries entries={$race.entries} />
    {/if}
  </div>
</section>
<section>
  <h2>Rules</h2>
  <ul>
    {#each $gameBrainErrors as error}
      <li>{error}</li>
    {/each}
  </ul>
  <button onclick={() => saveState($state.state)}>Save</button>
  <div class="text-container">
    <textarea rows={60} cols={57} bind:value={$rules}></textarea>
  </div>
</section>
<section>
  <h2>Logout</h2>
  <a href="/logout">Logout</a>
</section>

<style>
  .text-container {
    overflow: auto;
  }
</style>
