<script lang="ts">
import { preparedSpellsCount } from './stores/spells';
  interface Props {
    level: string;
  }

  let { level }: Props = $props();
</script>

<div class="bar">
  {#if level === '0'}
    <div class="level">Cantrips</div>
  {:else}
    <div class="level">Level {level}</div>
  {/if}
  {#if level !== '0'}
    <div>Prepared: {$preparedSpellsCount.levels[+level]}</div>
  {/if}
</div>

<style scoped>
  .bar {
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: var(--grokange-900);
    padding: 1em;
  }

  .level {
    font-size: large;
    font-weight: bold;
  }
</style>
