<script lang="ts">
import type { Attribute } from "@character-sheet/types";
import Value from "./Value.svelte";
import { abilities } from './stores/abilities';
interface Props {
  prop: Attribute;
}

let { prop }: Props = $props();
let value = abilities[prop];
</script>

<div class="stat">
  <div>{prop}</div>
  <div><Value modifier prop={`${prop}_modifier`} /></div>
  <input
    type="number"
    value={$value}
    min={0}
    max={20}
    onchange={(e) => $value = +e.currentTarget.value}
  />
</div>
