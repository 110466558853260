<script lang="ts">
import { SPELL_SCHOOLS } from "./spells";
	interface Props {
		school: string;
	}

	let { school }: Props = $props();

</script>
{SPELL_SCHOOLS[school]}
