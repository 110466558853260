<script lang="ts">
import {
  ritualFilter,
  concentrationFilter,
  higherLevelFilter,
  domainSpellFilter,
  rangedFilter,
  actionFilter,
  bonusActionFilter,
  vComponentFilter,
  sComponentFilter,
  mComponentFilter,
  cycle_filter,
  damageFilter,
  conditionFilter,
} from "./stores/spells";
import Filter from './Filter.svelte';
</script>

<div>
  <Filter label="Ritual" state={$ritualFilter} click={(state) => $ritualFilter = cycle_filter(state)} />
  <Filter label="Concentration" state={$concentrationFilter} click={(state) => $concentrationFilter = cycle_filter(state)} />
  <Filter label="Higher level" state={$higherLevelFilter}  click={(state) => $higherLevelFilter =  cycle_filter(state)} />
  <Filter label="Domain spell" state={$domainSpellFilter} click={(state) => $domainSpellFilter = cycle_filter(state)} />
  <Filter label="Ranged" state={$rangedFilter} click={(state) => $rangedFilter = cycle_filter(state)} />
  <Filter label="Action" state={$actionFilter} click={(state) => $actionFilter = cycle_filter(state)} />
  <Filter label="Bonus action" state={$bonusActionFilter} click={(state) => $bonusActionFilter = cycle_filter(state)} />
  <Filter label="V Component" state={$vComponentFilter} click={(state) => $vComponentFilter = cycle_filter(state)} />
  <Filter label="S Component" state={$sComponentFilter} click={(state) => $sComponentFilter = cycle_filter(state)} />
  <Filter label="M Component" state={$mComponentFilter} click={(state) => $mComponentFilter = cycle_filter(state)} />
  <Filter label="Damage" state={$damageFilter} click={(state) => $damageFilter = cycle_filter(state)} />
  <Filter label="Condition" state={$conditionFilter} click={(state) => $conditionFilter = cycle_filter(state)} />
</div>

<style scoped>
div {
  display: flex;
  align-items: center;
  align-content: baseline;
  justify-content: space-around;
  white-space: nowrap;
  flex-wrap: wrap;
  padding: 1em 0.5em 1em 0.5em;
  overflow: hidden;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
  row-gap: 0.5em;
}
</style>
