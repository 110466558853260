<script lang="ts">
  interface Props {
    duration: any;
  }

  let { duration }: Props = $props();
</script>

{#each duration as d}
  {#if d.type === "timed"}
    {d.duration.amount} {d.duration.type}{#if d.duration.amount !== 1}s{/if}
  {:else if d.type === "permanent"}
    Permanent
  {:else if d.type === "instant"}
    Instant
  {/if}
{/each}
