import { derived } from "svelte/store";
import * as gameBrain from 'game-brain';
import { classData } from "./classes";
import { acBonuses, currentHitDice, currentHP, level, meleeAttackBonuses, rules, userSavingThrows, userSkillProfs, weaponDamageBonuses } from "./stores";
import { abilities } from "./abilities";
import { selectedArmor, selectedWeapon } from "./items";
import { race } from "./races";
import type { Weapon } from "@character-sheet/types";

export type Dice = string;

export const gbInput = derived([
  level,
  classData,
  abilities.str,
  abilities.dex,
  abilities.con,
  abilities.int,
  abilities.wis,
  abilities.cha,
  selectedWeapon,
  selectedArmor,
  race,
  currentHP,
  currentHitDice,
  userSkillProfs,
  userSavingThrows,
  acBonuses,
  meleeAttackBonuses,
  weaponDamageBonuses,
], ([
  $level,
  $classData,
  $str,
  $dex,
  $con,
  $int,
  $wis,
  $cha,
  $selectedWeapon,
  $selectedArmor,
  $race,
  $currentHP,
  $currentHitDice,
  $userSkillProfs,
  $userSavingThrows,
  $acBonuses,
  $meleeAttackBonuses,
  $weaponDamageBonuses,
]) => {
  // Fuck me
  const skill_profs = $race?.skillProficiencies ? $race.skillProficiencies[0] : {};
  if(!$classData) {
    return {};
  }
  return {
    race_speed: $race?.speed,
    darkvision: $race?.darkvision ?? false,
    level: $level,
    hit_die: `${$classData.hd?.number}d${$classData.hd?.faces}`,
    str: $str,
    dex: $dex,
    con: $con,
    int: $int,
    wis: $wis,
    cha: $cha,
    saving_throws: [...$classData.proficiency || [], ...$userSavingThrows],
    skill_profs: [...Object.keys(skill_profs ?? {}), ...$userSkillProfs],
    weapon_die: $selectedWeapon?.dmg1,
    weapon_die_2: ($selectedWeapon as Weapon).dmg2 ?? 0,
    armor_ac: $selectedArmor?.ac,
    botf_weapon: false,
    armor_str: parseInt($selectedArmor?.strength ?? "0", 10),
    armor_stealth_disadvantage: $selectedArmor?.stealth ?? false,
    spellcasting_ability: $classData.spellcastingAbility ?? false,
    current_hp: $currentHP,
    current_hit_dice: $currentHitDice,
    ac_bonuses: Object.values($acBonuses),
    melee_attack_bonuses: Object.values($meleeAttackBonuses),
    weapon_damage_bonuses: Object.values($weaponDamageBonuses),
  };
});

const calculator_instance = derived(rules, ($rules) => {
  return gameBrain.new_calculator($rules ?? "");
});


export const calculator = derived(
  calculator_instance,
  ($calculator_instance) => {
    return $calculator_instance.data;
  },
);

export const calc = derived(
  [calculator, gbInput],
  ([$calculator, $gbInput]) => {
    return (prop: string) => {
      try {
        return gameBrain.calculate(prop, $calculator, $gbInput);
      } catch (e) {
        console.error(e);
      }
    };
  },
);

export const calcMayFail = derived(
  [calculator, gbInput],
  ([$calculator, $input]) => {
    return (prop: string) => {
      try {
        return gameBrain.calculate(prop, $calculator, $input);
      } finally {
        // It's OK to fail
      }
    };
  },
);

export const getRule = derived(
  [calculator],
  ([$calculator]) => {
    return (prop: string) => {
      try {
        return gameBrain.get_rule(prop, $calculator);
      } catch (e) {
        console.error(e);
      }
    };
  },
);

export const getRuleMayFail = derived(
  [calculator],
  ([$calculator]) => {
    return (prop: string) => {
      try {
        return gameBrain.get_rule(prop, $calculator);
      } finally {
        // It's OK to fail
      }
    };
  },
);

export const getRules = derived(
  [calculator],
  ([$calculator]) => {
    return () => {
      try {
        return gameBrain.get_rules($calculator);
      } catch (e) {
        console.error(e);
      }
    };
  },
);

export const gameBrainErrors = derived(
  calculator_instance,
  ($calculator_instance) => {
    return $calculator_instance.errors;
  },
);
