import type { Attribute } from "@character-sheet/types";
import { writable, type Writable } from "svelte/store";

export const abilities: { [key in Attribute]: Writable<number> } = {
  str: writable<number>(10),
  dex: writable<number>(10),
  con: writable<number>(10),
  int: writable<number>(10),
  wis: writable<number>(10),
  cha: writable<number>(10),
};
