import { derived, type Readable } from "svelte/store";

export default function<T>(stores: Array<Readable<unknown>> | Readable<unknown>, callback: (stores: any) => Promise<T>, initialValue: T): Readable<T> {
  let guard: unknown;

  return derived(
    stores,
    ($stores, set) => {
      const inner = (guard = {});

      set(initialValue);
      Promise.resolve(callback($stores)).then((value) => {
        if (guard === inner) {
          set(value);
        }
      });
    },
    initialValue,
  );
}
