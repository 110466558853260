<script lang="ts">
import Value from './Value.svelte';
import Card from './Card.svelte';
import type { ValueData } from './types';
  interface Props {
    stats: ValueData[];
  }

  let { stats }: Props = $props();
</script>

<Card>
  <table>
    <tbody>
      {#each stats as stat}
	<tr>
          <td>{stat.label}:</td>
          <td class="align-right"><Value modifier={stat.modifier} prop={stat.key} /></td>
        </tr>  
      {/each}
    </tbody>
  </table>
</Card>
