<script lang="ts">
import type { Snippet } from 'svelte';
import { currentPage, Page } from './core/router';

interface Props {
  page: Page;
  children?: Snippet;
}

let { page, children }: Props = $props();
</script>

<button class:active={$currentPage === page} onclick={() => $currentPage = page}>{@render children?.()}</button>

<style>
button {
  border: none;
  border-left: 1px solid var(--muted);
  border-bottom: 1px solid var(--muted);
  background-color: var(--grey-900);
  text-transform: uppercase;
  border-radius: inherit;
  margin: 0;
  padding: 1em;
  width: 100%;
}

button:hover {
  background-color: var(--muted);
  cursor: pointer;
}

.active {
  background-color: var(--grokange-700) !important;
}
</style>
