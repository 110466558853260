<script lang="ts">
  interface Props {
    text: string;
  }

  let { text }: Props = $props();

let parsed = $derived(text
  .split("{@")
  .reduce((acc, element) => {
    const end = element.indexOf("}");
    if(end > -1) {
      const stripped = element.substring(element.indexOf(" ") + 1, end);
      const remainder = element.substring(end + 1, element.length);
      return acc.concat(`<strong>${stripped}</strong>${remainder}`);
    }
    return acc;
  }));



// TODO: Optimize
</script>

{@html parsed}
