<script lang="ts">
import type { Snippet } from 'svelte';
import { fade } from 'svelte/transition';

interface Props {
  children?: Snippet;
}

let { children }: Props = $props();
</script>
<div class="tooltip" transition:fade={{ duration: 150}}>
  {@render children?.()}
</div>

<style>
.tooltip {
  position: absolute;
  background-color: var(--grokange-900);
  border: 1px solid var(--grokange-500);
  border-radius: var(--radius);
  padding: 1em;
  font-size: medium;
}
</style>
