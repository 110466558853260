<script lang="ts">
import type { Snippet } from 'svelte';
import { FilterState } from './stores/spells';

interface Props {
  label?: string | undefined;
  state: FilterState;
  disabled?: boolean;
  click: (state: FilterState) => void;
  children?: Snippet;
}

let {
  label = undefined,
  state,
  disabled = false,
  click,
  children
}: Props = $props();

function toggle() {
  if (disabled) {
    return;
  }

  click(state);
}
</script>

<span class="toggle">
  <button
    aria-label={label}
    class:include={state === FilterState.Include}
    class:exclude={state === FilterState.Exclude}
    class:disabled
    onclick={toggle}
    ></button>
  {#if label}<button class="toggle-label" onclick={toggle}>{label}</button>{/if}
    <span>{@render children?.()}</span>
</span>

<style scoped>
.toggle {
  margin: 0.25em 0;
  display: inline-flex;
  align-items: center;
  height: var(--toggle-size);
}

.include {
  background-color: green;
}

.exclude {
  background-color: red;
}

.disabled {
  border-color: var(--grokange-900);
  pointer-events: none;
}

.disabled.include {
  background-color: var(--grokange-800);
}

.disabled.exclude {
  background-color: var(--grokange-800);
}

button:hover {
  border-color: var(--white);
  cursor: pointer;
}

button {
  border: 2px solid var(--primary);
  background-color: transparent;
  border-radius: 50%;
  padding: calc(var(--toggle-size) / 2);
  cursor: auto;
  transition: background-color var(--anim-speed);
  transition: border-color var(--anim-speed);
  align-self: center;
}

.toggle-label {
  user-select: none;
  border: none;
  background-color: inherit;
  padding-right: 0.4em;
}

</style>
