import { derived, writable } from "svelte/store";
import { cachedStore } from "./cachedStore";
import { API_URL, get } from "../core/api";
import { Stash } from "../core/stash";
import type { Class, Classes, Subclass } from "@character-sheet/types";
import asyncDerived from "./asyncDerived";

// The name (key) of the currently selected class
export const characterClass = writable<string>();
export const characterSubclass = writable<string>();

export const classes = asyncDerived<Classes | null>(characterClass, async ($characterClass: string) => {
  // If the character class is unknown yet, we can't get data for it
  if (!$characterClass) return undefined;

  // First check if it's present in storage
  const cachedValue = Stash.getItem($characterClass);
  if (cachedValue) {
    return cachedValue;
  }

  // Fetch it from the API if it isn't
  const response = await get(`${API_URL}/classes/${$characterClass}`);
  const jsonResponse = await response.json();
  Stash.setItem($characterClass, jsonResponse.data);
  return jsonResponse.data;
}, null);

export const classData = asyncDerived<Class | null>(classes, async ($classes: Classes) => {
  return $classes?.class[0];
}, null);

export const subclass = derived([classes, characterSubclass], ([$classes, $characterSubclass]) => {
  return $classes?.subclass?.find((sc: Subclass) => sc.shortName === $characterSubclass);
});

// Key/value pair of all class names
export const classNames = cachedStore("classes");
