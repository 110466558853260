export const SPELL_SCHOOLS: Record<string, string> = {
  C: "Conjuration",
  A: "Abjuration",
  T: "Transmutation",
  E: "Enchantment",
  N: "Necromancy",
  D: "Divination",
  I: "Illusion",
  V: "Evocation",
};

export type RangeType = "point" | "touch";
