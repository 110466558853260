<script lang="ts">
  import type { Table } from "@character-sheet/types";

  interface Props {
    entry: Table;
  }

let { entry }: Props = $props();
</script>

<table>
  <thead>
    <tr>
      {#each entry.colLabels as colLabel, i}
        <th class={entry.colStyles[i]}>{colLabel}</th>
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each entry.rows as row}
      <tr>
        {#each entry.colLabels as _c, i}
          <td class={entry.colStyles[i]}>{row[i]}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>

<style style="scoped">

  table {
    margin: 1em 0;
    width: 100%;
  }

  th {
    font-weight: bold;
  }

  .text-center {
    text-align: center;
  }

  .col-3 {
    width: 25%;
  }

  .col-6 {
    width: 50%;
  }

</style>
