<script lang="ts">
import { calc } from './stores/gameBrain';
import { userSkillProfs } from './stores/stores';
import Toggle from './Toggle.svelte';
import Value from './Value.svelte';

  interface Props {
    label: string;
    proficiency: string;
  }

  let { label, proficiency }: Props = $props();
</script>

<div>
  <Toggle
    toggle={() => userSkillProfs.toggle(proficiency)}
    label={label}
    checked={$calc(`${proficiency}_prof`)}>
    <Value modifier prop={proficiency} />
  </Toggle>
</div>
