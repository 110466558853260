import { API_URL, get } from "../core/api";
import { readonly, writable, type Readable } from "svelte/store";
import type { Armor, BaseItem, Race, RangedWeapon, Weapon } from "@character-sheet/types";
import { Stash } from "../core/stash";

interface ApiData {
  races: Race[] & "race";
  classes: Record<string, string> & "classes";
  weapons: Array<BaseItem & (Weapon | RangedWeapon)> & "weapons";
  armor: Array<BaseItem & Armor> & "armor";
}

export function cachedStore<K extends keyof ApiData>(
  key: K,
): Readable<ApiData[K] | null> {
  const initialData = Stash.getItem<ApiData[K] | null>(key);
  const store = writable<ApiData[K] | null>(initialData);
  
  if (initialData === null) {
    get(`${API_URL}/${key}`).then((response) => {
      response.json().then((body) => {
        store.set(body.data);
        Stash.setItem(key, body.data);
      });
    });
  } 

  return readonly(store);
}
