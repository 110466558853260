<script lang="ts">
  interface Props {
    range: any;
  }

  let { range }: Props = $props();
</script>
{#if range.type === "point"}
  {#if range.distance.type === "touch"}
    Touch
  {:else if range.distance.type === "self"}
    Self
  {:else}
    {range.distance.amount} {range.distance.type}
  {/if}
{/if}
