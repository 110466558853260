<script lang="ts">
import type { Snippet } from 'svelte';
interface Props {
  label?: string | undefined;
  checked: boolean;
  disabled?: boolean;
  index?: number | undefined;
  toggle: ({ index, checked }: { index: number | undefined, checked: boolean }) => void;
  children?: Snippet;
}

let {
  label = undefined,
  checked,
  disabled = false,
  index = undefined,
  toggle,
  children
}: Props = $props();

function clickHandler() {
  if (disabled) {
    return;
  }

  toggle({ index, checked })
}
</script>

<div class="toggle">
  <div>
    <button
      aria-label={label}
      class:checked
      class:disabled
      class="toggle-btn"
      onclick={clickHandler}
      ></button>
  </div>
{#if label}<div><button class="toggle-label" onclick={clickHandler}>{label}</button></div>{/if}
  <div>{@render children?.()}</div>
</div>

<style scoped>
.toggle {
  margin: 0.5em 0;
  display: inline-flex;
  gap: 0.3rem;
  align-items: center;
  height: var(--toggle-size);
}

.checked {
  background-color: var(--primary) !important;
}

.disabled {
  border-color: var(--grokange-800) !important;
  pointer-events: none;
}

.toggle-btn:hover {
  border-color: var(--white);
  cursor: pointer;
}

.toggle-btn {
  border: 2px solid var(--primary);
  background-color: transparent;
  border-radius: 50%;
  padding: calc(var(--toggle-size) / 2);
  cursor: auto;
  transition: background-color var(--anim-speed);
  transition: border-color var(--anim-speed);
}

.toggle-label {
  user-select: none;
  border: none;
  background-color: inherit;
  padding-right: 0.4em;
}

</style>
