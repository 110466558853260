<script lang="ts">
import { preparedSpellsCount } from '../stores/spells';
import SpellSlots from '../SpellSlots.svelte';
import HitPoints from '../HitPoints.svelte';
import WeaponSelector from '../WeaponSelector.svelte';
import ArmorSelector from '../ArmorSelector.svelte';
import Card from '../Card.svelte';
import ResourceToggle from '../ResourceToggle.svelte';
import { calc } from '../stores/gameBrain';
import { selectedWeapon } from '../stores/items';
import Value from '../Value.svelte';

let spellsPrepared = $derived($calc('spells_prepared'));

</script>

<div class="header">
  <Card>
    <h4>Spell slots</h4>
    {#each [1,2,3,4,5,6,7,8,9] as level}
      <SpellSlots level={level} />
    {/each}
  </Card>
  <Card>
    <h4>Abilities</h4>
    <ResourceToggle label="Relentless endurance" prop="relentless_endurance" />
    <ResourceToggle label="CD: Turn undead" prop="cd_turn_undead" />
    <ResourceToggle label="CD: Destroy undead" prop="cd_destroy_undead" />
    <ResourceToggle label="CD: Artisan's Blessing" prop="cd_artisans_blessing" />
  </Card>
  <Card>
    <table>
      <tbody>
        <tr>
          <td>Spell Attack:</td>
          <td class="align-right"><Value modifier prop="spell_attack_modifier" /></td>
        </tr>
        <tr>
          <td>Spell Save DC:</td>
          <td class="align-right"><Value prop="spell_save_dc" /></td>
        </tr>
        <tr>
          <td>Spellcasting ability modifier:</td>
          <td class="align-right"><Value modifier prop="spellcasting_ability_mod" /></td>
        </tr>
        <tr>
          <td>Spells Prepared:</td>
          <td class="align-right"><span class:error={$preparedSpellsCount.total > spellsPrepared}>{$preparedSpellsCount.total}</span>/{spellsPrepared}</td>
        </tr>
        <tr class="spacer">
          <td>Melee Attack:</td>
          <td class="align-right"><Value modifier prop="melee_attack" /></td>
        </tr>
        <tr>
          <td>Melee damage:</td>
          <td class="align-right">
            <Value prop="melee_damage" /><br />{$selectedWeapon?.dmgType}
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
  <Card --max-width="13em">
    <div class="ac">
      <h4>AC</h4>
      <h2><Value prop="ac" /></h2>
    </div>
  </Card>
  <HitPoints />
  <WeaponSelector />
  <ArmorSelector />
</div>

<style scoped>
.align-right {
  text-align: right;
}

.header {
  display: flex;
  flex-wrap: wrap;
}

.ac {
  min-width: 6em;
  text-align: center;
  font-size: 120%;
}

.ac h2 {
  font-size: 230%;
}
</style>
