<script lang="ts">
  interface Props {
    time: any;
  }

  let { time }: Props = $props();
</script>
{#each time as t}
  {t.number} {t.unit}{#if t.number !== 1}s{/if}
{/each}
