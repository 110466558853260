<script lang="ts">
import Card from '../Card.svelte';
import Stats from '../Stats.svelte';
import HitPoints from '../HitPoints.svelte';
import WeaponSelector from '../WeaponSelector.svelte';
import ArmorSelector from '../ArmorSelector.svelte';
import Languages from '../Languages.svelte';
import ValueTable from '../ValueTable.svelte';
import { userSavingThrows } from '../stores/stores';
import Value from '../Value.svelte';
import ForgeClericCard from '../ForgeClericCard.svelte';
import Proficiencies from '../Proficiencies.svelte';
import SubclassFeatures from '../SubclassFeatures.svelte';
import Toggle from '../Toggle.svelte';
import { calc } from "../stores/gameBrain";
import { currentHitDice } from '../stores/stores';
import type { ValueData } from '../types';
import { classData, characterClass, characterSubclass } from '../stores/classes';
import { LABELS } from '../labels';

const stats: ValueData[] = [
  { label: 'HP', key: 'hit_points', modifier: false },
  { label: 'Speed', key: 'speed', modifier: false },
  { label: 'Initiative', key: 'initiative', modifier: true },
  { label: 'Proficiency bonus', key: 'prof_bon', modifier: true },
  { label: 'Passive perception', key: 'passive_perception', modifier: false },
  { label: 'Darkvision', key: 'darkvision', modifier: false },
  { label: 'Melee attack', key: 'melee_attack', modifier: true },
  { label: 'Melee damage', key: 'melee_damage', modifier: false },
  { label: 'AC', key: 'ac', modifier: false },
];
</script>
<Stats />
{#if $classData}
  <div class="container">
    <ValueTable stats={stats} />
    
    <Card>
      <h3>Hit dice:</h3>
      <span><Value modifier prop="hit_dice" /></span>
      <p><input type="number" bind:value={$currentHitDice} min={0} max={30} /></p>
      Chance to fully heal<div><Value percentage prop="heal_chance" /></div>
    </Card>
    <HitPoints />
    <Languages />
    <SubclassFeatures />
    <WeaponSelector />
    <ArmorSelector />
    {#if $characterClass === 'cleric' && $characterSubclass === 'Forge'}
      <ForgeClericCard />
    {/if}
    <Card>
      <h3>Saving throws</h3>
      {#each ["str", "dex", "con", "int", "wis", "cha"] as savingThrow}
        <div>
          <Toggle
            label={LABELS[savingThrow]?.label}
            checked={$calc(`${savingThrow}_st`)}
            toggle={() => userSavingThrows.toggle(savingThrow)}
            >
            <Value modifier prop={`${savingThrow}_saving_throw`} />
          </Toggle>
        </div>
      {/each}
    </Card>
    <Proficiencies />
  </div>
{/if}

<style>
.container {
  display: flex;
  flex-flow: wrap;
}
</style>
