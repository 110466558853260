<script lang="ts">
import Toggle from "./Toggle.svelte";
import Card from "./Card.svelte";
import { selectedWeapon, selectedArmor } from "./stores/items";
import { acBonuses, meleeAttackBonuses, weaponDamageBonuses } from "./stores/stores";
import { soulOfTheForge } from "./stores/forge";
</script>

<Card>
  <h3>Blessing of the Forge:</h3>
  {#if $selectedArmor}
    <p>
      <Toggle
        label={$selectedArmor.name}
        toggle={(e) => {
        acBonuses.toggle("botf", 1);
        if (!e.checked) {
        weaponDamageBonuses.remove("sotf");
        meleeAttackBonuses.remove("sotf");
        }
        }}
        checked={!!$acBonuses.botf}
        />
    </p>
  {/if}
  {#if $selectedWeapon}
    <p>
      <Toggle
        label={$selectedWeapon.name}
        toggle={(e) => {
                  weaponDamageBonuses.toggle("sotf", 1);
                  meleeAttackBonuses.toggle("sotf", 1);
                  if (!e.checked) {
                    acBonuses.remove('botf');
                  }
                  }}
        checked={!!$meleeAttackBonuses.sotf && !!$weaponDamageBonuses.sotf}
        />
    </p>
  {/if}
  <p>
    {#if $soulOfTheForge}
      <b>Soul of the Forge</b><br />
      {#if $soulOfTheForge}
        +1 AC
      {:else}
        Not wearing heavy armor
      {/if}
    {/if}
  </p>
</Card>
