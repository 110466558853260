import { derived, writable } from "svelte/store";
import { cachedStore } from "./cachedStore";

export const races = cachedStore("races");

export const characterRace = writable<string>();

export const race = derived([races, characterRace], ([$races, $characterRace]) => {
  return $races?.find((r) => r.name === $characterRace);
});
