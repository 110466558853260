<script lang="ts">
  import Entries from './Entries.svelte';
import Text from "./Text.svelte";
import Table from "./Table.svelte";

  interface Props {
    entries: any;
  }

  let { entries }: Props = $props();
</script>

<section>
  {#each entries as entry}
    
      {#if entry.type === "entries"}
        <h4>{entry.name}</h4>
        <Entries entries={entry.entries} />
      {:else if entry.type === "list"}
        <ul>
          {#each entry.items as item}
            <li>{item}</li>
          {/each}
        </ul>
      {:else if entry.type === 'table'}
        <Table entry={entry} />
      {:else if typeof entry === 'string'}
        <p><Text text={entry} /></p>
      {/if}
    {/each}
  </section>

<style>
  h4 {
    margin: 1.0em 0em 0em 0em;
    color: var(--grokange-500);
  }
  p {
    margin: 0.5em 0em 0em 0em;
  }
</style>
