<script lang="ts">
import Toggle from "./Toggle.svelte";
import { resources } from "./stores/stores";
import { calc } from "./stores/gameBrain";

interface Props {
  prop: string;
  label: string;
}

let { prop, label }: Props = $props();
let value = $derived($calc(prop));
</script>
{#if value}
  <div>
    <Toggle
      label={label}
      toggle={() => $resources[prop] = !$resources[prop]}
      checked={!!$resources[prop]}
      />
  </div>
{/if}
