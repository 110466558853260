export const LABELS: { [key: string]: { label: string, modifier: boolean }} = {
  // Input
  level: { label: "Level", modifier: false },
  str: { label: "Strength", modifier: false },
  dex: { label: "Dexterity", modifier: false },
  con: { label: "Constitution", modifier: false },
  int: { label: "Intelligence", modifier: false },
  wis: { label: "Wisdom", modifier: false },
  cha: { label: "Charisma", modifier: false },
  str_st: { label: "Strength saving throw", modifier: false },
  dex_st: { label: "Dexterity saving throw", modifier: false },
  con_st: { label: "Constitution saving throw", modifier: false },
  int_st: { label: "Intelligence saving throw", modifier: false },
  wis_st: { label: "Wisdom saving throw", modifier: false },
  cha_st: { label: "Charisma saving throw", modifier: false },
  athletics_prof: { label: "Athletics proficiency", modifier: false },
  acrobatics_prof: { label: "Acrobatics proficiency", modifier: false },
  soh_prof: { label: "Sleight of hand proficiency", modifier: false },
  stealth_prof: { label: "Stealth proficiency", modifier: false },
  arcana_prof: { label: "Arcana proficiency", modifier: false },
  history_prof: { label: "History proficiency", modifier: false },
  investigation_prof: { label: "Investigation proficiency", modifier: false },
  nature_prof: { label: "Nature proficiency", modifier: false },
  religion_prof: { label: "Religion proficiency", modifier: false },
  animal_handling_prof: {
    label: "Animal handling proficiency",
    modifier: false,
  },
  insight_prof: { label: "Insight proficiency", modifier: false },
  medicine_prof: { label: "Medicine proficiency", modifier: false },
  survival_prof: { label: "Survival proficiency", modifier: false },
  perception_prof: { label: "Perception proficiency", modifier: false },
  deception_prof: { label: "Deception proficiency", modifier: false },
  intimidation_prof: { label: "Intimidation proficiency", modifier: false },
  performance_prof: { label: "Performance proficiency", modifier: false },
  persuasion_prof: { label: "Persuasion proficiency", modifier: false },
  race: { label: "Race", modifier: false },
  race_speed: { label: "Race", modifier: false },
  class: { label: "Class", modifier: false },
  domain: { label: "Domain", modifier: false },
  monastic_tradition: { label: "Monastic tradition", modifier: false },
  weapon: { label: "Weapon", modifier: false },
  armor: { label: "Armor", modifier: false },
  armor_str: { label: "Armor strength requirement", modifier: false },
  armor_ac: { label: "Armor AC", modifier: false },
  shield: { label: "Shield", modifier: false },
  botf: { label: "Blessing of the forge", modifier: false },
  sotf: { label: "Soul of the forge", modifier: false },
  current_hit_dice: { label: "Current hit dice", modifier: false },
  current_hp: { label: "Current hit points", modifier: false },
  current_temp_hp: { label: "Current temporary hit points", modifier: false },
  saving_throws: { label: "Saving throws", modifier: false },
  ac_bonuses: { label: "AC bonus", modifier: false },
  melee_attack_bonuses: { label: "Melee attack bonus", modifier: false },
  weapon_damage_bonuses: { label: "Weapon damage bonus", modifier: false },
  
  // Game brain variables
  int_modifier: { label: "Intelligence modifier", modifier: true },
  con_modifier: { label: "Constitution modifier", modifier: true },
  str_modifier: { label: "Strength modifier", modifier: true },
  dex_modifier: { label: "Dexterity modifier", modifier: true },
  wis_modifier: { label: "Wisdom modifier", modifier: true },
  cha_modifier: { label: "Charisma modifier", modifier: true },
  str_saving_throw: { label: "Strength saving throw", modifier: false },
  con_saving_throw: { label: "Constitution saving throw", modifier: false },
  dex_saving_throw: { label: "Strength saving throw", modifier: false },
  int_saving_throw: { label: "Dexterity saving throw", modifier: false },
  wis_saving_throw: { label: "Wisdom saving throw", modifier: false },
  cha_saving_throw: { label: "Charisma saving throw", modifier: false },
  base_speed: { label: "Base speed", modifier: false },
  speed: { label: "Speed", modifier: false },
  initiative: { label: "Initiative", modifier: false },
  prof_bon: { label: "Proficiency bonus", modifier: false },
  cd_turn_undead: { label: "CD: Turn undead", modifier: false },
  cd_destroy_undead: { label: "CD: Destroy undead", modifier: false },
  cd_artisans_blessing: { label: "CD: Artisans blessing", modifier: false },
  passive_perception: { label: "Passive perception", modifier: false },
  stealth_disadvantage: { label: "Stealth disadvantage", modifier: false },
  ac: { label: "Armor class", modifier: false },
  hit_points: { label: "Hit points", modifier: false },
  hit_die: { label: "Hit die", modifier: false },
  hit_dice: { label: "Hit dice", modifier: false },
  heal_chance: { label: "Heal change", modifier: false },
  relentless_endurance: { label: "Relentless endurance", modifier: false },
  hp_missing: { label: "Hit points missing", modifier: false },
  weapon_die: { label: "Weapon die", modifier: false },
  weapon_range: { label: "Weapon range", modifier: false },
  weapon_versatile: { label: "Versatile", modifier: false },
  damage_type: { label: "Damage type", modifier: false },
  melee_attack: { label: "Melee attack", modifier: false },
  melee_damage: { label: "Melee damage", modifier: false },
  spell_save_dc: { label: "Spell save DC", modifier: false },
  spellcasting_ability: { label: "Spellcasting ability", modifier: false },
  spellcasting_ability_mod: {
    label: "Spellcasting ability modifier",
    modifier: false,
  },
  melee_attack_modifier: { label: "Melee attack modifier", modifier: false },
  spell_attack_modifier: { label: "Spell attack modifier", modifier: false },
  max_spell_level: { label: "Max spell level", modifier: false },
  spells_prepared: { label: "Spells prepared", modifier: false },
  lvl_1_slots: { label: "Level 1 spell slots", modifier: false },
  lvl_2_slots: { label: "Level 2 spell slots", modifier: false },
  lvl_3_slots: { label: "Level 3 spell slots", modifier: false },
  lvl_4_slots: { label: "Level 4 spell slots", modifier: false },
  lvl_5_slots: { label: "Level 5 spell slots", modifier: false },
  lvl_6_slots: { label: "Level 6 spell slots", modifier: false },
  lvl_7_slots: { label: "Level 7 spell slots", modifier: false },
  lvl_8_slots: { label: "Level 8 spell slots", modifier: false },
  lvl_9_slots: { label: "Level 9 spell slots", modifier: false },
  athletics: { label: "Athletics", modifier: false },
  acrobatics: { label: "acrobatics", modifier: false },
  sleight_of_hand: { label: "Sleight of Hand", modifier: false },
  stealth: { label: "Stealth", modifier: false },
  arcana: { label: "Arcana", modifier: false },
  history: { label: "History", modifier: false },
  investigation: { label: "investigation", modifier: false },
  nature: { label: "Nature", modifier: false },
  religion: { label: "Religion", modifier: false },
  animal_handling: { label: "Animal handling", modifier: false },
  insight: { label: "Insight", modifier: false },
  medicine: { label: "Medicine", modifier: false },
  perception: { label: "Perception", modifier: false },
  survival: { label: "Survival", modifier: false },
  deception: { label: "Deception", modifier: false },
  intimidation: { label: "Intimidation", modifier: false },
  performance: { label: "Performance", modifier: false },
  persuasion: { label: "Persuasion", modifier: false },
  blindsight: { label: "Blind sight", modifier: false },
  darkvision: { label: "Darkvision", modifier: false },
  termorsense: { label: "Tremor sense", modifier: false },
  truesight: { label: "True sight", modifier: false },
};
