<script lang="ts">
import Card from './Card.svelte';
import { subclass } from './stores/classes';
import { calc } from './stores/gameBrain';
</script>
{#if $subclass}
  <Card>
    <h3>Subclass features</h3>
    {#each $subclass.subclassFeatures as feature}
      {#if feature.level <= $calc("level")}<p>{feature.name}</p>{/if}
    {/each}
  </Card>
{/if}
