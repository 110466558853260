<script lang="ts">
import { LABELS } from './labels';
	interface Props {
		key: string;
	}

	let { key }: Props = $props();
</script>

{LABELS[key]?.label ?? key}
