<script lang="ts">
import type { Weapon } from '@character-sheet/types';
import Card from './Card.svelte';
import Value from './Value.svelte';
import { weapons, selectedWeaponName, selectedWeapon } from './stores/items';

function handleChange(e: Event) {
  const target = e.currentTarget as HTMLInputElement;
  $selectedWeaponName = target.value;
}

</script>
<Card>
  
  <h3>Current weapon</h3>
  {#if $weapons}
    <select
      class="selector"
      onchange={handleChange}
      value={$selectedWeaponName}
      >
      {#each $weapons as weapon}
        <option value={weapon.name}>{weapon.name}</option>
      {/each}
    </select>
  {/if}
  <div class="content">
    <table>
      <tbody>
	<tr>
          <td>Damage:</td>
          <td class="align-right"><Value prop="melee_damage" /><br />{$selectedWeapon?.dmgType}</td>
        </tr>
        {#if $selectedWeapon?.range}
          <tr>
            <td>Range:</td>
            <td class="align-right">{$selectedWeapon.range}</td>
          </tr>
        {/if}
        {#if ($selectedWeapon as Weapon).dmg2}
          <tr>
            <td>2H:</td>
            <td class="align-right"><Value prop="melee_damage_2" /></td>
          </tr>
        {/if}
      </tbody>
    </table>
  </div>
</Card>

<style scoped>
.selector {
  margin: 1em 0;
}
</style>
