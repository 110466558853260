<script lang="ts">
import Value from './Value.svelte';
import { getRuleMayFail, calc } from './stores/gameBrain';
import { input } from './stores/stores';
import Tooltip from './Tooltip.svelte';
import Label from './Label.svelte';
import { LABELS } from './labels';
import { type Input } from '@character-sheet/types';
interface Props {
  prop: string;
  modifier?: boolean | undefined;
  percentage?: boolean | undefined;
}

let { prop, modifier = false, percentage = false }: Props = $props();
let tooltip: boolean = $state(false);
let permanent: boolean = $state(false);
let value = $derived($input[prop as keyof Input] !== undefined ? $input[prop as keyof Input] : $calc(prop));
let deps = $derived([...new Set<any>($getRuleMayFail(prop))]);

</script>

<button
  onmouseover={() => tooltip = true}
  onmouseout={() => { if(!permanent) { tooltip = false }}}
  onfocus={() => tooltip = true}
  onblur={() => tooltip = false}
  onclick={() => permanent = !permanent}
  >
  {#if typeof value === 'boolean'}
    {value ? 'Yes' : 'No'}
  {:else if modifier}
    {#if value > 0}+{/if}{value}
  {:else if percentage}
    {(value * 100).toFixed(2)}%
  {:else if typeof value === 'object'}
    {#each Object.entries(value) as [k, v]}
      <Label key={k} />: {v}<br />
    {/each}
  {:else}
    {value}
  {/if}
</button>
{#if tooltip && deps.length > 0}
  <Tooltip>
    <table>
      <tbody>
        {#each deps as dep}
          <tr>
            <td>
              <Label key={dep} />
            </td>
            <td>
              <Value prop={dep} modifier={LABELS[dep] ? LABELS[dep].modifier : false}/>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </Tooltip>
{/if}

<style scoped>
button {
  display: inline;
  border: inherit;
  background: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  text-align: right;
}

</style>
