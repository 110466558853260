import { writable } from "svelte/store";

export enum Page {
  Settings = "settings",
  Sheet = "sheet",
  Spells = "spells",
  Prepared = "prepared",
  Combat = "combat",
}

const initialPage: Page = (localStorage.getItem("page") ||
  Page.Settings) as Page;

export const currentPage = writable<Page>(initialPage);

currentPage.subscribe(($newPage) => localStorage.setItem("page", $newPage));
