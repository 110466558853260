import "./app.css";
import App from "./App.svelte";
import Login from "./pages/Login.svelte";
import { get } from "./core/api";
import initWasm from 'game-brain';
import { API_URL } from "./core/api";
import { mount } from "svelte";

const init = async () => {
  await initWasm();
  const whoami = await get(`${API_URL}/whoami`);
  const target = document.querySelector("#app");
  if (target) {
    if (whoami.status === 200) {
      mount(App, {
                target,
              });
    } else {
      mount(Login, { target });
    }
  }
};

init();
