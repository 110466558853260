<script lang="ts">
  import SpellCard from '../SpellCard.svelte';
  import { calc } from '../stores/gameBrain';
  import { filteredSpellsByLevel, preparedSpellsCount } from '../stores/spells';
  import SpellFilters from '../SpellFilters.svelte';
  import LevelHeader from '../LevelHeader.svelte';
  import Value from '../Value.svelte';
  let sp = $derived($calc('spells_prepared'));
</script>
<div class="sticky">
  <SpellFilters />
  <h3>
    Prepared: <span class:error={$preparedSpellsCount.total > sp}>{$preparedSpellsCount.total}</span>/<Value prop="spells_prepared" />
  </h3>
</div>
{#each Object.entries($filteredSpellsByLevel) as [level, spells]}
  <LevelHeader level={level} />
  {#each spells as spell}
    <SpellCard spell={spell} />  
  {/each}
{/each}

<style scoped>
.sticky {
  position: fixed;
  bottom: var(--nav-bar-height);
  left: 0;
  background-color: var(--background-dark);
  width: 100%;
  z-index: 9999;
}

h3 {
  margin: 0;
  padding-left: 1em;
  padding-bottom: 0.5em;
}

</style>
