import { derived } from "svelte/store";
import { subclass } from "./classes";
import { acBonuses, level } from "./stores";
import { selectedArmor } from "./items";

export const soulOfTheForge = derived([level, subclass, selectedArmor], ([$level, $subclass, $selectedArmor]) => {
  const feature = $subclass?.subclassFeatures?.find((sf) => sf.name === "Soul of the Forge");
  if(feature) {
    const conditionsMet = feature.level <= $level && $selectedArmor?.type === "HA";
    
    if (conditionsMet) {
      acBonuses.add("sotf", 1);
    } else {
      acBonuses.remove("sotf");
    }

    return conditionsMet;
  } else {
    return false;
  }
});
