<script lang="ts">
import Toggle from "./Toggle.svelte";
import { calc } from './stores/gameBrain';
import { spellSlotsUsed } from './stores/stores';

const MAX_SLOTS: number = 4;

interface Props {
  level: number;
}

let { level }: Props = $props();

let slots: number = $derived($calc(`lvl_${level}_slots`));

function toggle(event: { index: number | undefined, checked: boolean }) {
  // Turn off all if current is clicked
  if ($spellSlotsUsed[level] === event.index) {
    // reset
    spellSlotsUsed.setSpellSlotUsed(level, -1);
  } else {
    spellSlotsUsed.setSpellSlotUsed(level, event.index ?? -1);
  }
}
</script>

{#if slots > 0}
  <div class="spell-level">
    <span>{level}</span>
    {#each [...Array(MAX_SLOTS).keys()] as index}
      <span class="spell-slot">
        <Toggle
          index={index}
          toggle={toggle}
          disabled={index >= slots}
          checked={$spellSlotsUsed[level] ? index <= $spellSlotsUsed[level] : false}
          />
      </span>
    {/each}
  </div>
{/if}

<style>
.spell-slot {
  margin-left: 0.5em;
}

.spell-level {
  margin-bottom: 0.5em;
  min-width: 12em;
}
</style>
