<script lang="ts">
import { race } from './stores/races';
import Card from './Card.svelte';
</script>

<Card>
  <h3>Languages</h3>
  {#if $race?.languageProficiencies && $race.languageProficiencies[0]}
    <ul>
      {#each Object.entries($race.languageProficiencies[0]) as [language, proficient]}
        {#if proficient}<li>{language}</li>{/if}
          {/each}
        </ul>
      {/if}
    </Card>

<style scoped>
ul {
  padding: 1em;
  margin: 0;
}

li {
  text-transform: capitalize;
}
</style>
