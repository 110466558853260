import { cachedStore } from "./cachedStore";
import { derived, writable } from "svelte/store";

export const weapons = cachedStore("weapons");
export const armor = cachedStore("armor");
  
export const selectedWeaponName = writable<string | null>(null);
export const selectedArmorName = writable<string | null>(null);

export const selectedArmor = derived([armor, selectedArmorName], ([$armor, $selectedArmorName]) => {
  return $armor?.find((a) => a.name === $selectedArmorName);
});

export const selectedWeapon = derived([weapons, selectedWeaponName], ([$weapons, $selectedWeaponName]) => {
  return $weapons?.find((w) => w.name === $selectedWeaponName);
});
